<template>
  <base-section id="k-d-a-blog-list" class="white" space="80">
    <v-container class="pa-0 px-7">
      <v-row justify="center" style="row-gap: 32px">
        <v-col cols="12">
          <v-row align="start">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="query"
                outlined
                :label="m_strSearch"
                prepend-inner-icon="mdi-magnify"
                class="pt-1 kda-ts-16pt nunito"
                clearable
                hide-details
                :height="g_bLowestBr ? 44 : 'auto'"
                @click:clear="clearQuery"
              ></v-text-field>
            </v-col>

            <v-col v-if="m_arrQueries.length > 0" cols="12" md="6">
              <v-sheet>
                <v-chip-group show-arrows>
                  <v-chip
                    v-for="(tag, i) in m_arrQueries"
                    :key="tag + '-' + i"
                    :class="`my-0 mx-2 kda-ts-16pt nunito wt-bold ${i === 0 && 'ml-auto'}`"
                    label
                    close
                    color="main-r-450"
                    text-color="white"
                    :large="!g_bLowerBr"
                    @click:close="() => {}"
                  >
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" lg="9">
          <v-row :style="{ rowGap: g_bLowerBr ? '32px' : '54px' }">
            <v-col v-for="(blog, i) in m_arrShownBlogs" :key="`list-of-blog-${i}`" cols="12" sm="6">
              <k-d-a-blog-card
                :str-title="blog.strTitle"
                :str-description="blog.strDescription"
                :str-label="blog.strLabel"
                :hex-label-color="blog.hexLabelColor"
                :img-src="blog.imgSrc"
                @info="
                  $router.push({
                    name: 'KDABlogDetails',
                    params: { strHashedID: blog.strID ?? 'dummy' }
                  })
                "
              />
            </v-col>

            <v-col v-if="!m_bShownAll" cols="12" class="d-flex justify-center">
              <v-btn color="main-bw-300" class="white--text text-capitalize" @click="page++">
                {{ m_strButton }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col v-if="!g_bBaseBr" cols="3">
          <v-row no-gutters style="gap: 14px; background: #f9f9f9" class="ml-6 px-6 py-4">
            <v-col cols="12">
              <h4 class="kda-ts-20pt nunito wt-extrabold">{{ m_strTrendingTitle }}</h4>
            </v-col>

            <v-col
              v-for="(trending, index) in m_arrTrendingBlogs"
              :key="`list-of-trending-blog-${index}`"
              cols="12"
            >
              <k-d-a-blog-card
                small
                :str-title="trending.strTitle"
                :str-description="trending.strDescription"
                :str-label="trending.strLabel"
                :hex-label-color="trending.hexLabelColor"
                :img-src="trending.imgSrc"
                @info="
                  $router.push({
                    name: 'KDABlogDetails',
                    params: { strHashedID: trending.strID ?? 'dummy' }
                  })
                "
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import KDABlogList from './extension/KDABlogList';

export default {
  name: 'KDABlogList',

  components: {
    KDABlogCard: () => import('@/components/KDABlogCard')
  },

  extends: KDABlogList,

  mixins: [BaseBreakpoint],

  data() {
    return {
      page: 1,
      query: ''
    };
  },

  computed: {
    m_arrQueries() {
      // TODO: add handler for searched queries
      // return ['Engineer', 'Designer', 'Sales', 'Business'];
      return [];
    },
    m_strTrendingTitle() {
      return this.$vuetify.lang.t('$vuetify.kda.blog.trend');
    },
    m_strButton() {
      return this.$vuetify.lang.t('$vuetify.kda.blog.load');
    },
    m_strSearch() {
      return this.$vuetify.lang.t('$vuetify.kda.blog.search');
    },
    m_iShownLimit() {
      return this.g_bLowestBr ? 3 : 4;
    },
    m_arrFilteredBlogs() {
      return this.m_arrBlogs.filter((blog) =>
        blog.strTitle.toLowerCase().includes(this.$data.query.toLowerCase())
      );
    },
    m_arrShownBlogs() {
      return this.m_arrFilteredBlogs.slice(0, this.page * this.m_iShownLimit);
    },
    m_bShownAll() {
      return this.m_arrShownBlogs.length === this.m_arrFilteredBlogs.length;
    }
  },

  watch: {
    query() {
      this.$data.page = 1;
    }
  },

  methods: {
    clearQuery() {
      this.$data.query = '';
    }
  }
};
</script>
