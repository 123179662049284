var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-section',{staticClass:"white",attrs:{"id":"k-d-a-blog-list","space":"80"}},[_c('v-container',{staticClass:"pa-0 px-7"},[_c('v-row',{staticStyle:{"row-gap":"32px"},attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"start"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"pt-1 kda-ts-16pt nunito",attrs:{"outlined":"","label":_vm.m_strSearch,"prepend-inner-icon":"mdi-magnify","clearable":"","hide-details":"","height":_vm.g_bLowestBr ? 44 : 'auto'},on:{"click:clear":_vm.clearQuery},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),(_vm.m_arrQueries.length > 0)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-sheet',[_c('v-chip-group',{attrs:{"show-arrows":""}},_vm._l((_vm.m_arrQueries),function(tag,i){return _c('v-chip',{key:tag + '-' + i,class:`my-0 mx-2 kda-ts-16pt nunito wt-bold ${i === 0 && 'ml-auto'}`,attrs:{"label":"","close":"","color":"main-r-450","text-color":"white","large":!_vm.g_bLowerBr},on:{"click:close":() => {}}},[_vm._v(" "+_vm._s(tag)+" ")])}),1)],1)],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('v-row',{style:({ rowGap: _vm.g_bLowerBr ? '32px' : '54px' })},[_vm._l((_vm.m_arrShownBlogs),function(blog,i){return _c('v-col',{key:`list-of-blog-${i}`,attrs:{"cols":"12","sm":"6"}},[_c('k-d-a-blog-card',{attrs:{"str-title":blog.strTitle,"str-description":blog.strDescription,"str-label":blog.strLabel,"hex-label-color":blog.hexLabelColor,"img-src":blog.imgSrc},on:{"info":function($event){return _vm.$router.push({
                  name: 'KDABlogDetails',
                  params: { strHashedID: blog.strID ?? 'dummy' }
                })}}})],1)}),(!_vm.m_bShownAll)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"white--text text-capitalize",attrs:{"color":"main-bw-300"},on:{"click":function($event){_vm.page++}}},[_vm._v(" "+_vm._s(_vm.m_strButton)+" ")])],1):_vm._e()],2)],1),(!_vm.g_bBaseBr)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{staticClass:"ml-6 px-6 py-4",staticStyle:{"gap":"14px","background":"#f9f9f9"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"kda-ts-20pt nunito wt-extrabold"},[_vm._v(_vm._s(_vm.m_strTrendingTitle))])]),_vm._l((_vm.m_arrTrendingBlogs),function(trending,index){return _c('v-col',{key:`list-of-trending-blog-${index}`,attrs:{"cols":"12"}},[_c('k-d-a-blog-card',{attrs:{"small":"","str-title":trending.strTitle,"str-description":trending.strDescription,"str-label":trending.strLabel,"hex-label-color":trending.hexLabelColor,"img-src":trending.imgSrc},on:{"info":function($event){return _vm.$router.push({
                  name: 'KDABlogDetails',
                  params: { strHashedID: trending.strID ?? 'dummy' }
                })}}})],1)})],2)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }